import logo from './lemonada_stand.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="logo" alt="logo" />
        <p>
          A web comic from <a href="https://www.leesheppard.com">Lee Sheppard</a>.
        </p>
      </header>
    </div>
  );
}

export default App;
